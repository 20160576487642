/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import Select from "react-select";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {useFirstMountState} from "react-use";

import {toast} from "react-toastify";
import {CSSTransition} from "react-transition-group";
import CreatableSelect from "react-select/creatable";
import TaggingTooltip from "../../../../Community/Components/TaggingTooltip";
import {KTSVG} from "../../../../../../_metronic/helpers";
import useAnnotation from "../../../../../../store/annotation/useAnnotation";
import {getAnnotationFilters, getAnnotationFiltersDefinition} from "../../../../../../_metronic/helpers/backend_helper";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import {FilterDataType, FilterListResponse, SegmentFilter} from "../../../../../../AUTO_GENERATED_TYPES";
import useGetAnnotationFilterList from "../../../../Community/Hooks/useGetAnnotationFilterList";
import isFilterFull from "../../../../../../assets/utils/isFilterFull";
import {AnnotationSegFilter} from "../../../../../../types/AnnotationTypes";
import {v4 as uuidv4} from "uuid";
import ROUTE_PATHS from '../../../../../routing/RoutePaths';

interface AnnotationFilterProps {
    isOpen: boolean,
    isItemTable?: boolean,
    onClose: () => any
}

const AnnotationFilter = ({
                              isOpen,
                              onClose,
                              isItemTable = false
                          }: AnnotationFilterProps) => {

    const navigate = useNavigate();
    const {id, communityIdx} = useParams()
    const [searchParams] = useSearchParams()
    const annotationId = Number(id)

    const {
        data: {dataRequest, filters: filterList, tagFilter, filterCount},
        functions: {
            filterReset,
            setFilters,
            addFilter,
            removeFilter,
            filterValueChange,
            filterOperatorChange,
            filterTypeChange,
            updateDataRequest
        }
    } = useAnnotation()

    const onlyFullFilters = useMemo(() =>
        filterList.filter(isFilterFull).map(filter => ({
            filter_definition_id: Number.parseInt(filter.filterType.value),
            filter_value: filter.filterValue.map(el => el.value),
            filter_operator: filter.filter_operator.value
        })), [filterList])

    const queryClient = useQueryClient()

    const {definitions: filterListDefinition, getOperators} = useGetAnnotationFilterList()

    useEffect(() => {
        const closeModal = () => {
            onClose()
        }

        document.body.addEventListener('click', closeModal)
        return () => {
            document.body.removeEventListener('click', closeModal)

        }
    }, [])

    const {
        data: filterListData,
        refetch
    } = useQuery([QueryKeys.AnnotationFiltersDefinition, annotationId], () => getAnnotationFiltersDefinition(annotationId), {
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: false
    })
    const communityId = filterListData?.filters?.find(desc => desc.column_name === 'comm_idx')?.id

    const allowAdd = useMemo(() => filterList[0]?.filterValue[0]?.value, [filterList])

    const firstMountState = useFirstMountState();

    useEffect(() => {
        if (firstMountState) {
            const urlFromJSON = JSON.parse(searchParams.get("json") ?? "[]");

            if (urlFromJSON.length > 0) {
                const decodedFilters = urlFromJSON.map((filter) => ({
                    ...filter,
                    filterValue: filter.filterValue.map((v) => ({
                        value: v,
                        label: v,
                    })),
                }));
                setFilters(decodedFilters);
                setTimeout(() => {
                    updateDataRequest();
                }, 300);
            }
        }
    }, [firstMountState, setFilters, updateDataRequest]);

    useEffect(() => {
        if (isItemTable) {
            filterReset()
            return
        }
        if (firstMountState) {
            const urlFromJSON = (JSON.parse(searchParams.get('json') ?? 'null') ?? []) as SegmentFilter[]

            refetch().then(({data}) => {
                if (!communityIdx && urlFromJSON.length === 0) {
                    return
                }
                const communityId = data?.filters?.find(desc => desc.column_name === 'comm_idx')?.id!

                getAnnotationFilters(annotationId, communityId, dataRequest, tagFilter).then(response => {
                    queryClient.setQueryData([QueryKeys.AnnotationFilters, annotationId, communityId, dataRequest, tagFilter], response)
                    const operators = response.filter_operator_values?.map(value => ({value, label: value}))
                    const values = response.filter_list?.map(filter => ({
                        value: filter.filter_name!,
                        label: `${filter.filter_name} (${filter.filter_counter})`
                    }))

                    const filterFromURL = [{}] as AnnotationSegFilter[]
                    if (communityIdx) {
                        filterFromURL[0].filterType = {
                            value: String(communityId),
                            label: 'Community Index'
                        }
                        filterFromURL[0].filter_operator = {value: '==', label: '=='}
                        filterFromURL[0].filterValue = [{
                            value: communityIdx!,
                            label: values!.find(value => value.value === communityIdx)!.label
                        }]
                        filterFromURL[0].filter_operators = operators ?? [{value: '', label: ''}]
                        filterFromURL[0].filterValues = values ?? [{value: '', label: ''}]
                        filterFromURL[0].dataType = response.filter_data_type
                        filterFromURL[0].id = uuidv4()
                    } else {
                        filterFromURL.pop()
                    }
                    let promises;
                    if (urlFromJSON.length > 0) {
                        promises = urlFromJSON.map(element => {
                            return getAnnotationFilters(annotationId, element.filter_definition_id, dataRequest, tagFilter).then(response => {
                                queryClient.setQueryData([QueryKeys.AnnotationFilters, annotationId, element.filter_definition_id, dataRequest, tagFilter], response)
                                const operators = response.filter_operator_values?.map(value => ({value, label: value}))
                                const values = response.filter_list?.map(filter => ({
                                    value: filter.filter_name!,
                                    label: `${filter.filter_name} (${filter.filter_counter})`
                                }))

                                const label = data?.filters?.find(desc => desc.id === element.filter_definition_id)?.description


                                const newFilterFromURL = {} as AnnotationSegFilter
                                newFilterFromURL.filterType = {
                                    value: String(element.filter_definition_id),
                                    label: label ?? ''
                                }
                                newFilterFromURL.filter_operator = operators?.find(operator => operator.value === element.filter_operator) ?? {
                                    value: '==',
                                    label: '=='
                                }
                                newFilterFromURL.filterValue = element.filter_value.map(filterValue => ({
                                    value: filterValue,
                                    label: values!.find(valueFromList => valueFromList.value === filterValue)!.label
                                }))
                                newFilterFromURL.filter_operators = operators ?? [{value: '', label: ''}]
                                newFilterFromURL.filterValues = values ?? [{value: '', label: ''}]
                                newFilterFromURL.dataType = response.filter_data_type
                                newFilterFromURL.id = uuidv4()

                                filterFromURL.push(newFilterFromURL)
                            })
                        })
                    }
                    Promise.all(promises).finally(() => setFilters(filterFromURL))
                })

            }).catch(reason => (console.log(reason)))
        }
    }, [communityIdx]);


    const addNewFilter = () => {
        if (allowAdd) {
            addFilter()
        }
    }

    const resetFilter = () => {
        filterReset()
        if (!isItemTable) {
            navigate(`/annotation/${annotationId}`)
        }
    }

    const handleFilterData = () => {
        onClose()
        updateDataRequest()
    }


    const deleteFilter = (id: string) => {
        if (filterList.length === 1) {
            resetFilter()
        } else {
            removeFilter(id)
        }
    }

    const filterTypeHandleChange = (id: string, selectedOption: any) => {

        const meta = queryClient.getQueryData([QueryKeys.AnnotationFilters, annotationId, selectedOption.value, onlyFullFilters, tagFilter]) as FilterListResponse
        if (meta) {
            filterTypeChange(id, selectedOption, meta)
        } else {

            getAnnotationFilters(annotationId, selectedOption.value, {filters: onlyFullFilters}, tagFilter).then(response => {
                queryClient.setQueryData([QueryKeys.AnnotationFilters, annotationId, selectedOption.value, onlyFullFilters, tagFilter], response)
                filterTypeChange(id, selectedOption, response)
            }).catch(() => toast.error('Something went wrong! Please try again.'))
        }
    }

    const filterOperatorHandleChange = (id: string, selectedOperator: any,) => {
        filterOperatorChange(id, selectedOperator)
    }

    const filterValueHandleChange = (id: string, selectedOption: any) => {
        filterValueChange(id, selectedOption)

    };


    return (
        <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="modal_animation"
            unmountOnExit
        >
            <div onClick={(e) => e.stopPropagation()} className={'segment_filter_window'}>
                <Card className="filter_card">
                    <CardBody id="advanced_filter" className={'position-relative'}>
                        {filterList.some(filter => filter.dataType === FilterDataType.NUMERIC) &&
                            <div style={{position: "absolute", right: '10px', top: '10px', cursor: 'pointer'}}
                                 onClick={() => {
                                     const numberFilters = filterList.filter(filter => filter.dataType === FilterDataType.NUMERIC).map(filter => {
                                         const filterForRequest: SegmentFilter = {
                                             filter_value: filter.filterValue.map(option => option.value),
                                             filter_operator: filter.filter_operator.value,
                                             filter_definition_id: Number.parseInt(filter.filterType.value)
                                         }

                                         return filterForRequest
                                     })
                                     const encodedFilterList = encodeURI(window.location.href.split('?').at(0) + '?json=' + JSON.stringify(numberFilters))
                                     navigator.clipboard.writeText(encodedFilterList).then(() => {
                                         toast.info('Link Copied to clipboard!')
                                     })
                                 }}><TaggingTooltip
                                placement={'right'} tooltip={'Copy URL Link (Only Numeric Filters)'}><KTSVG
                                path='/media/icons/duotune/communication/com008.svg'
                                className={'svg-icon-1 active_paste'}
                            /></TaggingTooltip></div>}
                        <h4 className="mb-4 text-start">Advanced Filter</h4>
                        {filterList.map(({id}, idx) => (
                            <Row key={idx} style={{marginBottom: "10px"}} className="align-items-center">
                                <Col sm="3">
                                    {filterListDefinition && <Select
                                        value={filterList[idx].filterType}
                                        //@ts-ignore
                                        options={filterListDefinition}
                                        onChange={(e) => filterTypeHandleChange(id, e)}
                                        styles={{
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                color: 'black',
                                            }),
                                        }}
                                    />}
                                </Col>
                                <Col sm="3">
                                    {getOperators && <Select
                                        isDisabled={filterList[idx].filterType.value === ""}
                                        value={filterList[idx].filter_operator}
                                        options={filterList[idx].filter_operators}
                                        onChange={(e) => filterOperatorHandleChange(id, e)}
                                        styles={{
                                            menu: (baseStyles) => ({
                                                ...baseStyles,
                                                color: 'black',
                                            }),
                                        }}
                                    />}
                                </Col>
                                <Col sm={filterList[idx].dataType === 'JSON' ? '4' : '5'}>
                                    {filterList[idx].dataType === 'STRING' ? <Select
                                            isDisabled={filterList[idx].filter_operator.value === ""}
                                            value={filterList[idx].filterValue}
                                            //@ts-ignore
                                            options={filterList[idx].filterValues}
                                            placeholder="Choose Filter Values ..."
                                            isMulti={true}
                                            onChange={(e) => filterValueHandleChange(id, [e].flat())}
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            styles={{
                                                menu: (baseStyles) => ({
                                                    ...baseStyles,
                                                    color: 'black',
                                                }),
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    maxHeight: '35px',
                                                    overflowY: 'auto'
                                                }),
                                            }}
                                        /> :
                                        <CreatableSelect
                                            isDisabled={filterList[idx].filter_operator.value === ""}
                                            value={filterList[idx].filterValue}
                                            //@ts-ignore
                                            options={filterList[idx].filterValues}
                                            placeholder="Choose Filter Values ..."
                                            onChange={(e) => filterValueHandleChange(id, [e].flat())}
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            styles={{
                                                menu: (baseStyles) => ({
                                                    ...baseStyles,
                                                    color: 'black',
                                                }),
                                                valueContainer: (baseStyles) => ({
                                                    ...baseStyles,
                                                    maxHeight: '35px',
                                                    overflowY: 'auto'
                                                }),
                                            }}
                                            formatCreateLabel={(inputValue: string) => `Filter by: ${inputValue}`}
                                            isValidNewOption={inputValue => !isNaN(parseInt(inputValue))}
                                            createOptionPosition={'first'}
                                        />

                                    }

                                </Col>
                                {filterList[idx].dataType === 'JSON' && <Col sm='1'>
                                    <TaggingTooltip placement={'right'} tooltip={'Paste Value'}>
                                        <div onClick={() => {
                                            if (filterList[idx].filter_operator.value === "") {
                                                return
                                            }
                                            navigator.clipboard.readText().then(text => {
                                                const convertedText = text.replaceAll('\\', '\"').replaceAll(',[', ', [')
                                                const newFilter = filterList[idx].filterValues.find(option => option.value === convertedText)
                                                if (newFilter) {
                                                    filterValueHandleChange(id, [newFilter].flat())
                                                }
                                            })
                                        }} className={filterList[idx].filter_operator.value === "" ? '' : 'del_button'}>
                                            <KTSVG path='/media/icons/duotune/arrows/arr092.svg'
                                                   className={`svg-icon-1 ${filterList[idx].filter_operator.value === "" ? 'inactive_plus' : 'active_paste'}`}
                                            />
                                        </div>
                                    </TaggingTooltip>
                                </Col>}
                                <Col sm="auto">
                                    <TaggingTooltip placement={'right'} tooltip={'Delete Filter'}>
                                        <div onClick={() => deleteFilter(id)} className={'del_button'}>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className={'svg-icon-1 active_delete'}
                                            />
                                        </div>
                                    </TaggingTooltip>
                                </Col>
                            </Row>
                        ))}
                        <Row className="align-items-center mt-2">
                            <Col sm="10">
                                {filterList.length <= 4 &&
                                    <TaggingTooltip placement={'left'}
                                                    tooltip={!allowAdd ? 'Complete the filter first' : 'Add New'}>
                                        <div onClick={addNewFilter} className={'plus_button'}>
                                            <KTSVG path='/media/icons/duotune/general/gen041.svg'
                                                   className={`svg-icon-1 ${!allowAdd ? 'inactive_plus' : 'active_plus'}`}
                                            />
                                        </div>
                                    </TaggingTooltip>}
                            </Col>
                            <Col sm="1">
                                <TaggingTooltip placement={'bottom'} tooltip={'Reset Filter'}>
                                    <div onClick={resetFilter} className={'refresh_button'}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr029.svg'
                                               className='svg-icon-1 active_plus'/>
                                    </div>
                                </TaggingTooltip>
                            </Col>
                            <Col sm="auto">
                                <TaggingTooltip placement={'bottom'} tooltip={'Apply Filters'}>
                                    <div onClick={handleFilterData} className={'refresh_button'}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr027.svg'
                                               className='svg-icon-1 active_plus'/>
                                    </div>
                                </TaggingTooltip>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </CSSTransition>
    )
}

export default AnnotationFilter
