import TaggingTooltip from "./TaggingTooltip";
import React, {useCallback} from "react";
import {toast} from "react-toastify";
import {KTSVG} from "../../../../_metronic/helpers";

interface LongDetailCellProps {
    data: any[] | string
    routeOnClick?: (arg?:any) => void,
    isLink?: boolean
}

const LongDetailCell: React.FC<LongDetailCellProps> = ({data, routeOnClick,isLink}) => {

    const handleClick = useCallback(() => {
        const formatArray = (arr: any[]): string => {
            return arr
                .map(item => {
                    if (Array.isArray(item)) {
                        return `[${formatArray(item)}]`;
                    }
                    return `"${String(item)}"`;
                })
                .join(", ");
        };
    
        const formattedData = Array.isArray(data) ? `[${formatArray(data)}]` : String(data);
    
        navigator.clipboard
            .writeText(formattedData)
            .then(() =>
                toast.success("Copied to clipboard!", {
                    position: "bottom-center",
                    autoClose: 1000,
                    closeButton: false,
                })
            );
    }, [data]);
    

    if (data === 'N/A') {
        return <div>{data}</div>
    }
    return String(data).length > 0 ? <TaggingTooltip placement={'bottom'}
                                                     tooltip={Array.isArray(data) ? data.join(' , ') : data}>
        <div
            style={{
                display: 'flex',
                gap: '5px'
            }}
        ><p className={Array.isArray(data) || isLink ? 'svg-icon-edit cursor-pointer' : ''} style={{
            overflow: "hidden",
            textOverflow: 'ellipsis',
            maxHeight:'40px'
        }}
            onClick={routeOnClick}
        >{Array.isArray(data) ? data[0] : data}</p>
            <span style={{cursor: 'pointer'}} onClick={handleClick}
            ><KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className='svg-icon-edit svg-icon-2'
            /></span>
        </div>

    </TaggingTooltip> : <div>N/A</div>
}

export default LongDetailCell
