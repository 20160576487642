import React, {useEffect} from "react";
import {
    AnnotationSegmentListResponse,
    AnnotationSegmentResponse,
    UpdateAnnotationSegmentRequest
} from "../../../../../../AUTO_GENERATED_TYPES";
import {Input, Modal, ModalBody, ModalHeader} from "reactstrap";
import classes from './AnnotationDetailsModal.module.scss'
import DetailsLink from "./DetailsLink";
import DetailsAdditionalData from "./DetailsAdditionalData";
import {useMutation, useQueryClient} from "react-query";
import {updateAnnotationSegmentComment} from "../../../../../../_metronic/helpers/backend_helper";
import {useParams} from "react-router-dom";
import {debounce} from "lodash";
import {toast} from "react-toastify";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import {ModalTitle} from "react-bootstrap";
import useAnnotation from "../../../../../../store/annotation/useAnnotation";
import {AnnotationSegFilter} from "../../../../../../types/AnnotationTypes";
import replaceComma from "../../../../../../assets/utils/replaceStringComma";
import { KTSVG } from "../../../../../../_metronic/helpers";

interface AnnotationDetailsModalProps {
    closeHandler: () => void
    segment: AnnotationSegmentResponse | null
    changeSegment: (segment: AnnotationSegmentResponse)=>void
    showZoomModal?: (segment?: AnnotationSegmentResponse) => void
}

const AnnotationDetailsModal: React.FC<AnnotationDetailsModalProps> = ({
                                                                           closeHandler,
                                                                           segment,
                                                                           changeSegment,
                                                                           showZoomModal
                                                                       }) => {
    const {id} = useParams()
    const annotationId = Number(id)

    const {
        data: {dataRequest, tagFilter, page, itemsPerPage},
    } = useAnnotation()
    const queryClient = useQueryClient()

    const {functions: {addFilter}} = useAnnotation()

    const segments = (JSON.parse(JSON.stringify(queryClient.getQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page]))) as AnnotationSegmentListResponse)?.segments
    const annotationIndex = segments?.findIndex((seg => seg.id === segment?.id))

    const {mutate} = useMutation<UpdateAnnotationSegmentRequest, unknown, any, string>((requestBody: UpdateAnnotationSegmentRequest) => updateAnnotationSegmentComment(annotationId, segment?.id ?? 0, requestBody), {
        onMutate: (variables) => {
            return variables.comment
        },
        onSuccess: (variable, variables) => {
            toast.success('Comment Updated!')
            const newData = JSON.parse(JSON.stringify(queryClient.getQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page]))) as AnnotationSegmentListResponse
            const newSeg = newData.segments?.find(seg => seg.id === segment?.id)
            if (newSeg) {
                newSeg.comment = variables.comment
                queryClient.setQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page], newData)
            }
        }
    })

    useEffect(() => {
        let handleNavigation: any;

        if (annotationIndex !== -1 && segments && segment) {
            const previous = segments[(annotationIndex ?? 0) - 1]
            const next = segments[(annotationIndex ?? 0) + 1]

            handleNavigation = (event: KeyboardEvent) => {
                if (event.key === 'ArrowRight' && next) {
                    changeSegment(next)
                } else if (event.key === 'ArrowLeft' && previous) {
                    changeSegment(previous)

                }
            }

            document.addEventListener('keydown', handleNavigation)

        return () => {
            document.removeEventListener('keydown', handleNavigation)
        }
        }
    }, [annotationIndex, segments,segment])

    if (!segment) {
        return null
    }


    const linkHandler = (filter?: (AnnotationSegFilter | undefined)) => {
        closeHandler()
        addFilter(filter)
    }

    const commentChangeHandler = debounce((e: React.ChangeEvent<HTMLInputElement>) => mutate({comment: e.target.value}), 1000)

    const openZoomHandler = () => {
        if (showZoomModal && segment) {
            showZoomModal(segment);
        }
    };

    return <Modal isOpen={true}
                  toggle={closeHandler}
                  centered
                  size="xl"
    >
        <ModalHeader toggle={closeHandler} className={classes.header__wrapper}>
            <div className={classes.header}>
                <ModalTitle>Details for <span className={classes.header__blue}>{segment.seg_id}</span></ModalTitle>
                <div>Class: <span className={classes.header__blue}>{segment.tag_name}</span></div>
                <div
                    className={classes.header__page}>{annotationIndex !== undefined ? annotationIndex + 1 : 0} / {itemsPerPage} -
                    Page {page}</div>
            </div>
        </ModalHeader>
        <ModalBody>
            <div className={classes.filter_links}>
                <DetailsLink label={'Segment Id'} data={segment.seg_id} type={'seg_id'} onClick={linkHandler}/>
                <DetailsLink label={'Platform'} data={segment.platform} type={'platform'} onClick={linkHandler}/>
                <DetailsLink label={'Country Code'} data={segment.country_code ?? ''} type={'country_code'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Additional Id'} data={segment.additional_id ?? ''} type={'additional_id'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Community Index'} data={String(segment.comm_idx)} type={'comm_idx'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Screen Width'} data={String(segment.screen_width)} type={'screen_width'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Screen Height'} data={String(segment.screen_height)} type={'screen_height'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Ip Network'} data={segment.ip_network ?? ''} type={'ip_network'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Ip Address'} data={segment.ip_address ?? ''} type={'ip_address'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Model'} data={segment.model ?? ''} type={'model'} onClick={linkHandler}/>
                <DetailsLink label={'Auth Models'} data={segment.auth_models ? replaceComma(JSON.stringify(segment.auth_models)).replaceAll(',[',', [') : ''} type={'auth_models'} onClick={linkHandler}/>
                <DetailsLink label={'Emu Models'} data={segment.emu_models ? replaceComma(JSON.stringify(segment.emu_models)).replaceAll(',[',', [') : ''} type={'emu_models'} onClick={linkHandler}/>
                <DetailsLink label={'Input Devices Found'} data={segment.input_devices_found? replaceComma(JSON.stringify(segment.input_devices_found)).replaceAll(',[',', [') : ''} type={'input_devices_found'} onClick={linkHandler}/>

                <DetailsLink label={'Segment Name'} data={segment.seg_name} type={'seg_name'} onClick={linkHandler}/>
                <DetailsLink label={'Session Id'} data={segment.session_id} type={'session_id'} onClick={linkHandler}/>
                <DetailsLink label={'User Id'} data={segment.user_id} type={'user_id'} onClick={linkHandler}/>
                <DetailsLink label={'SDK Ver. Name'} data={segment.sdk_version_name ?? ''} type={'sdk_version_name'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Device'} data={segment.device ?? ''} type={'device'} onClick={linkHandler}/>
                <DetailsLink label={'Device Id'} data={segment.device_id ?? ''} type={'device_id'}
                             onClick={linkHandler}/>
                <DetailsLink label={'TS Start'} data={segment.ts_start ?? ''} type={'ts_start'} onClick={linkHandler}/>
                <DetailsLink label={'System Version'} data={segment.system_version ?? ''} type={'system_version'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Count Unique XY'} data={String(segment.cnt_unique_xy ?? '')} type={'cnt_unique_xy'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Additional Info'} data={segment.additional_info ?? ''} type={'additional_info'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Extended Info'} data={segment.extended_info ?? ''} type={'extended_info'}
                             onClick={linkHandler}/>
                <DetailsLink label={'Bio Durations Millis'} data={String(segment.bio_duration_millis ?? '')}
                             type={'bio_duration_millis'} onClick={linkHandler}/>
                <DetailsLink label={'Auth Inference Canary'} data={String(segment.auth_inference_canary ?? '')}
                             type={'auth_inference_canary'} onClick={linkHandler}/>
                <DetailsLink label={'Comm Idx Canary'} data={String(segment.comm_idx_canary ?? '')}
                             type={'comm_idx_canary'} onClick={linkHandler}/>
                <DetailsLink label={'Most Common XY'} data={String(segment.most_common_xy ?? '')}
                             type={'most_common_xy'} onClick={linkHandler}/>
                <DetailsLink label={'Key Values'}
                             data={segment.key_values ? replaceComma(JSON.stringify(segment.key_values)).replaceAll(',[',', [') : ''}
                             type={'key_values'} onClick={linkHandler}/>


            </div>
            <div className={classes.additional_data}>
                <DetailsAdditionalData label={'Auth Score'} data={segment.auth_score}/>
                <DetailsAdditionalData label={'# Motion Down'} data={segment.cnt_motion_down}/>
                <DetailsAdditionalData label={'# Segments'} data={segment.cnt_segments}/>
                <DetailsAdditionalData label={'# Sequence Acc'} data={segment.cnt_seq_acc}/>
                <DetailsAdditionalData label={'# Sequence Motion'} data={segment.cnt_seq_motion}/>
                <DetailsAdditionalData label={'Emu Score'} data={segment.emu_score}/>
                <DetailsAdditionalData label={'Input Devices Inference'} data={segment.input_devices_inference}/>
                <DetailsAdditionalData label={'Labeled Auth Label'} data={segment.labeled_auth_label}/>
                <DetailsAdditionalData label={'# Seg In Session'} data={segment.seg_count}/>
                <DetailsAdditionalData label={'Emu Inference'} data={segment.emu_inference}/>
                <DetailsAdditionalData label={'# Seg Duration Millis'} data={segment.seg_duration_millis}/>
                <DetailsAdditionalData label={'# Motion Move'} data={segment.cnt_motion_move}/>
                <DetailsAdditionalData label={'Segment Ids'} data={segment.segment_ids}/>
                <DetailsAdditionalData label={'Community Indexes'} data={segment.comm_indices}/>

                <div className={classes.comment}>Comment: <Input defaultValue={segment.comment}
                                                                 type={"textarea"}
                                                                 onChange={commentChangeHandler}/>
                    <span onClick={openZoomHandler} className={classes.mag_glass}>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen004.svg'
                                    className={'svg-icon-edit svg-icon-2qx cursor-pointer'}
                                />
                            </span>
                </div>
            </div>
                                                                 
        </ModalBody>
    </Modal>
}

export default AnnotationDetailsModal
