export const updateQueryParams = (newParams, setSearchParams) => {
    const queryParams = new URLSearchParams(window.location.search);
    Object.entries(newParams).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            queryParams.set(key, value.toString());
        } else {
            queryParams.delete(key);
        }
    });
    setSearchParams(queryParams, { replace: true });
};
